import React from "react";
import { getSubjects } from "utility/helper";
import { NewSelect } from "components/atomes";

const TeacherOption = ({ className, values, setValues, setValuesAndForm }) => {
  return (
    <>
      <h3>إختر الطور</h3>
      <div className={className}>
        <NewSelect
          name="phase"
          value={values.phase[1]}
          options={[
            ["إختر الطور", "إختر الطور"],
            ["الإبتدائي", 1],
            ["المتوسط", 2],
            ["الثانوي", 3],
          ]}
          setValue={setValues}
        />
        <h3>إختر المادة</h3>
        <NewSelect
          name="subject"
          value={values.subject}
          options={getSubjects(values.phase[1])}
          setValue={setValuesAndForm("info")}
        />
      </div>
    </>
  );
};

export default TeacherOption;
