import React from "react";
import { getLevels, getDivisions } from "utility/helper";
import { NewSelect } from "components/atomes";

const StudentOptions = ({
  className = "card--signUp",
  values,
  setValues,
  setValuesAndForm,
}) => {
  const getNextStep = values => {
    const { level, division } = values;
    const today = new Date().toISOString();
    if (
      level[1] === 3 &&
      (division[1] === 1 || division[1] === 2 || division[1] === 3) &&
      !today.startsWith("2024-10-12")
    ) {
      return "tutoring";
    }
    return "info";
  };
  return (
    <>
      <h3>إختر الطور</h3>
      <div className={className}>
        <NewSelect
          name="phase"
          value={values.phase[1]}
          options={[
            ["إختر الطور", "إختر الطور"],
            ["الإبتدائي", "1"],
            ["المتوسط", "2"],
            ["الثانوي", "3"],
          ]}
          setValue={setValues}
        />
        <NewSelect
          value={values.level[1]}
          options={getLevels(values.phase[1])}
          setValue={
            values.phase[1] === "3" ? setValues : setValuesAndForm("info")
          }
          name="level"
        />
        {values.phase[1] === "3" && (
          <NewSelect
            value={values.division[1]}
            options={getDivisions(values.level[1])}
            setValue={setValuesAndForm(getNextStep)}
            name="division"
          />
        )}
      </div>
    </>
  );
};

export default StudentOptions;
