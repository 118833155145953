import React, { lazy, Suspense } from "react";
import { Router } from "@reach/router";

import PrivateRoute from "../components/privateRoute";
import Loader from "components/loader/Loader";
import Layout from "components/layout";
import CompleteRegistration from "components/molecules/Modals/CompleteRegistration";
const Profile = lazy(() => import("../components/profile"));
const ThankYou = lazy(() => import("../components/thankyou"));
const PaymentsResult = lazy(() => import("../components/PaymentsResult"));
const Dashboard = lazy(() => import("../components/dashboard"));
const Admin = lazy(() => import("components/dashboard/AdminDashboard"));

const App = () => {
  const isSSR = typeof window === "undefined";
  return (
    <>
      {!isSSR && (
        <Suspense
          fallback={
            <Layout title="جاري التحميل">
              <Loader />
            </Layout>
          }
        >
          <Router basepath="/App">
            <PrivateRoute
              path="/completeRegistration"
              component={CompleteRegistration}
            />
            <PrivateRoute path="/thankyou" component={ThankYou} />
            <PrivateRoute path="/paymentsResult" component={PaymentsResult} />
            <PrivateRoute path="/profile" component={Profile} />
            <PrivateRoute path="/dashboard" component={Dashboard} />
            <PrivateRoute path="/admin" component={Admin} />
          </Router>
        </Suspense>
      )}
    </>
  );
};

export default App;
