import React from "react";
import { Button } from "components/atomes";
import { StaticImage } from "gatsby-plugin-image";

const UserType = ({ handelPreSignup, className }) => {
  return (
    <>
      <h3> هل أنت: </h3>
      <div className={className}>
        <Button handelClick={handelPreSignup("teacher")}>
          <div
            style={{ display: "flex", flexDirection: "column", padding: "1em" }}
          >
            <StaticImage
              src="../../../../images/teacherAvatar.png"
              alt="teacher"
              objectFit="contain"
            />
            <span>أستاذ</span>
          </div>
        </Button>
        <Button handelClick={handelPreSignup("student")}>
          <div
            style={{ display: "flex", flexDirection: "column", padding: "1em" }}
          >
            <StaticImage
              src="../../../../images/studentAvatar.png"
              alt="student"
              objectFit="contain"
            />
            <span>تلميذ</span>
          </div>
        </Button>
        <Button handelClick={handelPreSignup("parent")}>
          <div
            style={{ display: "flex", flexDirection: "column", padding: "1em" }}
          >
            <StaticImage
              src="../../../../images/parentAvatar.png"
              alt="parent"
              objectFit="contain"
            />
            <span>ولي أمر</span>
          </div>
        </Button>
      </div>
    </>
  );
};

export default UserType;
