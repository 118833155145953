import React from "react";
import { navigate } from "gatsby";

import { useUser } from "../context/userContext";
import Layout from "components/layout";
import Loader from "components/loader";

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const {
    userDetail: { isLoggedIn, profile, isLoading },
  } = useUser();

  if (isLoading) {
    return (
      <Layout title="من فضلك انتظر حتى يتم التحميل ">
        <Loader />
      </Layout>
    );
  }
  if (
    !isLoggedIn &&
    location.pathname !== `/Signin` &&
    location.pathname !== `/Signup`
  ) {
    if (location.hash !== "") {
      navigate(`/Signup${location.search}${location.hash}`);
      return null;
    }
    navigate("/Signin");
    return null;
  }

  return <Component location={location} profile={profile} {...rest} />;
};
export default PrivateRoute;
