import React, { useEffect, useState } from "react";

import UserType from "./UserType";
import Logo from "components/Logo";
import TeacherOption from "./TeacherOption";
import StudentOptions from "./StudentOptions";
import PersonalInfos from "./PersonalInfos";
import Summary from "./Summary";
import Tutoring from "./Tutoring";
import StarsOffer from "./StarsOffer";

const CompleteRegistration = ({ id }) => {
  const [values, setValues] = useState({
    subject: "إختر المادة",
    level: "إختر المستوى",
    division: "إختر الشعبة",
    phase: ["إختر الطور", "إختر الطور"],
    userType: "user",
    firstName: "",
    lastName: "",
    phone: "",
    day: "",
    time: "",
  });
  const [formState, setFormState] = useState("preSignup");

  const setValuesAndForm = step => fn => {
    setValues(state => {
      const newState = fn(state);
      if (typeof step === "function") {
        setFormState(step(newState));
      } else {
        setFormState(step);
      }
      return newState;
    });
  };
  const handelPreSignup = userType => e => {
    e.preventDefault();
    setFormState(
      userType === "user" || userType === "parent" ? "info" : userType
    );
    setValues(state => ({ ...state, userType }));
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        gap: "2em",
        paddingTop: "1em",
        alignItems: "center",
      }}
    >
      <Logo />
      <div
        style={{
          width: "90%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "1em",
          justifyContent: "center",
        }}
      >
        {formState === "preSignup" && (
          <UserType
            className="card--userType"
            handelPreSignup={handelPreSignup}
          />
        )}
        {formState === "teacher" && (
          <TeacherOption
            className="card--signUp"
            values={values}
            setValues={setValues}
            setValuesAndForm={setValuesAndForm}
          />
        )}
        {formState === "student" && (
          <StudentOptions
            values={values}
            setValues={setValues}
            setValuesAndForm={setValuesAndForm}
          />
        )}
        {formState === "tutoring" && (
          <Tutoring
            values={values}
            setValues={setValues}
            setForm={setFormState}
          />
        )}
        {formState === "offer" && (
          <StarsOffer
            values={values}
            setValues={setValues}
            setValuesAndForm={setValuesAndForm}
          />
        )}
        {formState === "info" && (
          <PersonalInfos
            values={values}
            setFormState={setFormState}
            setValues={setValues}
          />
        )}

        {formState === "signup" && <Summary values={values} id={id} />}
      </div>
    </div>
  );
};

export default CompleteRegistration;
